.containers-body {
	background-color: #F7F7F7;
	max-width: 420px;
	left: calc(-50vw + 50%);
	right: calc(-50vw + 50%);
	margin-left: auto;
	margin-right: auto;

 	.not-found-page{
        text-align: center;
        padding-top: calc(50vh - 100px);
		height: 100vh;

		&.status{
			padding: 40px;
			padding-top: calc(50vh - 155.3px);
		}
		
		.not-found-text{
			font-family: 'Manrope';
			font-size: 18px;
			font-weight: 600;
			font-stretch: normal;
			font-style: normal;
			line-height: normal;
			letter-spacing: 0.51px;
			text-align: center;
			color: #fbb12f;
			margin-top: 15px;

			&.back-apps{
				font-size: 14px !important;
				font-weight: 600 !important;
				font-stretch: normal !important;
				font-style: normal !important;
				line-height: normal !important;
				letter-spacing: normal !important;
				text-align: center !important;
				color: #6c6d71 !important;
			}
		}
    }
}

@media only screen and (min-width: 720px) {
	.containers-body {
		height: 720px !important;
		margin-top: calc((100vh - 720px)/2) !important;
		position: relative;

		.not-found-page{
			height: 720px !important;
			padding-top: calc(360px - 80.3px);
			&.status{
				padding: 40px;
				padding-top: calc(360px - 155.3px);
			}
			// padding-top: calc((100vh - 544.4px)/2) !important;
		}
	}
}