@media only screen and (min-width: 568px) and (min-height: 510px) and (max-height: 639px) {
}

@media only screen and (min-device-width: 720px) {
  .layout-container {
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;

    height: 590px !important;
    box-shadow: 0px 12px 24px rgb(30 44 106 / 15%);
    border-radius: 5px;
    overflow: hidden;

    position: absolute;
    width: 420px !important;
    top: 50%;
    margin-top: -295px;
    max-width: calc(420px - 16px * 2);
    left: 50%;
    margin-left: calc(-1 * calc(420px - 16px * 2) / 2);
    max-height: 608px;
  }
  .order-modal {
    position: relative;
    left: 0;

    .order-modal-dialog {
      position: fixed;
      left: 16px;
      right: 16px;
      height: auto;
      z-index: 51;
      overflow: hidden;
      box-shadow: 0 2px 12px rgb(30 44 106 / 15%);
      border-radius: 10px;
      max-width: calc(360px - 2px * 2);
      max-height: 500px;
      top: 50% !important;
      margin-top: -205px;
    }
    .order-modal-body {
      height: calc(100% - 48px);
    }
    .order-box {
      overflow: auto;
      background: #ffffff;
      box-shadow: 0 2px 12px rgb(30 44 106 / 15%);

      font-style: normal;

      background-image: url('./../assets/NEWbg.svg');
      background-repeat: no-repeat;
      border-radius: 10px;

      background-size: 60% 100%;
      background-position: 100% 100%;
    }
    .order-summary-separator-style {
      border: 1px solid #e6e9ef;
    }

    .order-modal .content-table {
      left: 0;
      right: 0;
      max-height: 280px;
    }
    .scroll-y {
      overflow-y: auto;
    }

    .content-table {
      height: 100%;
      width: 100%;
      position: relative;
    }
    .order-modal-wrapper {
      padding: 0 12px;
    }
    .order-summary-header {
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 19px;
      color: #2d2e34;
      padding-bottom: 10px;
    }
  }
  // .e-money{
  //   min-height: 40vh !important;
  // }
}

@media only screen and (min-width: 568px) {
  .layout-container {
    height: 100%;
  }
}

@media only screen and (min-device-width: 720px) and (min-device-height: 720px) {
  .body-container {
    height: 410px !important;
  }

  .card-container {
    .content-cont {
      position: relative;
    }
  }
}

// responsesife modal web
@media only screen and (min-width: 400px) {
  .order-modal-dialog {
    max-width: 388px;
    left: calc(-50vw + 50%);
    right: calc(-50vw + 50%);
    margin-left: auto;
    margin-right: auto;
  }
  .body-container {
    padding-bottom: 5rem !important;
  }
}

.layout-container {
  background-color: #ffffff;

  ::-webkit-scrollbar {
    width: 3px;
    height: 1px !important;
    background-color: none;
  }

  ::-webkit-scrollbar-thumb {
    background: #fbb02f51;
  }

  ::-webkit-scrollbar-track {
    background: none;
  }
  @media only screen and (max-device-width: 700px) {
    max-width: 420px;
    left: calc(-50vw + 50%);
    right: calc(-50vw + 50%);
    margin-left: auto;
    margin-right: auto;
    height: 100vh;
  }
}

.body-container {
  height: calc(100vh - 210px);

  padding-bottom: 50px;
  padding-top: 0.2rem;

  ::-webkit-scrollbar {
    width: 3px;
    height: 1px !important;
    background-color: none;
  }

  ::-webkit-scrollbar-thumb {
    background: #fbb02f51;
  }

  ::-webkit-scrollbar-track {
    background: none;
  }
  overflow-x: hidden;
}

.header-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 16px;
  margin-right: 16px;
}
.bgColor {
  // background-image: linear-gradient(107deg, #fdd95c -3%, #fbb12f 116%);
  background-color: #ffffff !important;
}
.cardHeader {
  border-radius: 10px;
  width: 100%;
  box-shadow: 0 2px 12px rgb(30 44 106 / 15%);

  .bgHeader {
    background-image: url('./../assets/NEWbg.svg');
    background-repeat: no-repeat;
    border-radius: 10px;
    width: 100%;
    padding: 20px;
    height: 160px;

    .text {
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.54px;
    }

    .orderId {
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #231f20;
      margin-top: 1rem;
    }

    .totalAmount {
      width: 100%;
      object-fit: contain;
      text-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
      font-size: 30px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #383435;
    }
    .card-header-bottom {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: flex-end;
    }
    // .cardMerchan-bottom {
    //   display: flex;
    // }
  }
}

.card-container {
  display: flex;
  // background-color: #191717;
  justify-content: center;
  align-items: center;
  // padding: 0px 15px 10px 15px;
  margin-top: 10px;
  max-width: calc(420px - 16px * 2);
  margin-left: 16px;
  margin-right: 16px;

  // left: 50%;
  // margin-left: calc(-1 * calc(420px - 16px * 2) / 2);

  .content-cont {
    width: 100%;
    padding: 5px 10px;
    border-radius: 8px;
    border: solid 0.5px #dddddd;
    background-color: #ffffff !important;
    align-items: center;
  }
}

.titleInput {
  width: 100%;
  background-color: #f6e2ac;
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  padding: 10px;
  text-align: center;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #f48e2d;
}

.inputNumber {
  .label-input {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #524e51;
  }
  input[type='number']::-webkit-outer-spin-button,
  input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type='number'] {
    -moz-appearance: textfield;
  }
  .input-phone {
    width: 100%;
    border: none;
    outline: none;
    border-bottom: solid 0.5px #9e9e9e71;
    background-color: transparent;
    &.active {
      border-bottom: solid 0.5px red !important;
    }
  }
}

.error-message {
  color: red;
}

.hitam {
  z-index: 5;
  top: 0;
  width: 100%;
  max-width: 420px;
  height: 74px;
  padding: 15px 27px 13px;
  background-color: #191717;
}

@media only screen and (max-width: 720px) {
  .hitam {
    position: fixed;
    margin-top: 0 !important;
  }
  .languange-container {
    margin-top: 70px !important;
  }
}

.price {
  text-overflow: clip;
  white-space: wrap;
}

.borderCard {
  background-color: #dddddd;
  padding: 1.3px;
  opacity: 0.7px;
}

.modal {
  top: 8.2%;
  width: 100%;
}

// utama

.order-modal {
  position: relative;
  left: 0;

  .order-modal-dialog {
    position: fixed;
    top: 90px;
    left: 16px;
    right: 16px;
    height: auto;
    z-index: 51;
    overflow: hidden;
    box-shadow: 0 2px 12px rgb(30 44 106 / 15%);
    background-color: #ffffff !important;
    border-radius: 10px;
  }
  .order-modal-body {
    height: calc(100% - 48px);
  }
  .order-box {
    overflow: auto;
    background: #ffffff;
    box-shadow: 0 2px 12px rgb(30 44 106 / 15%);

    font-style: normal;

    background-image: url('./../assets/NEWbg.svg');
    background-repeat: no-repeat;
    border-radius: 10px;

    background-size: 60% 100%;
    background-position: 100% 100%;
  }
  .order-summary-separator-style {
    border: 1px solid #e6e9ef;
  }

  .order-modal .content-table {
    left: 0;
    right: 0;
    max-height: 280px;
  }
  .scroll-y {
    overflow-y: auto;
  }

  .content-table {
    height: 100%;
    width: 100%;
    position: relative;
  }
  .order-modal-wrapper {
    padding: 0 12px;
  }
  .order-summary-header {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 19px;
    color: #2d2e34;
    padding-bottom: 10px;
  }
}

.header {
  padding-bottom: 10px;
  margin-block-start: 0;
  margin-block-end: 0;

  .title-bar {
    background: #191717;
    color: #ffffff;
    overflow: auto;
    height: 90px;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .logo-store {
      display: inline-block;
    }
    .merchant-name {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
      padding: 9px 0;
      display: inline-block;
    }
  }
  .order-header {
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0) 50%, #191717 50%);

    .order-box {
      overflow: auto;

      margin-left: 16px;
      margin-right: 16px;
      background: #ffffff;
      box-shadow: 0 2px 12px rgb(30 44 106 / 15%);

      font-style: normal;

      background-image: url('./../assets/NEWbg.svg');
      background-repeat: no-repeat;
      border-radius: 10px;

      background-size: 60% 100%;
      background-position: 100% 100%;

      .order-summary-section {
        margin: 25px;
        .expiry-countdown-label {
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 16px;
          color: #8e919b;
        }
        .expiry-countdown-timer {
          min-width: 60px;
          display: inline-block;
          text-align: center;
          color: #3e62fe;
        }
        .header-amount {
          font-style: normal;
          font-weight: 600;
          font-size: 21px;
          line-height: 29px;
          color: #2d2e34;
          display: inline-block;
          font-weight: 600;
          font-size: 24px;
          line-height: 29px;
          color: #383435;
          text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.0750639);
        }

        .logo {
          vertical-align: middle;
        }
        .header-order-id {
          margin-top: 10px;
          display: inline-block;
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 15px;
          color: #231f20;
        }
      }
    }
  }
}

// .order-modal-dialog {
//   position: fixed;
//   top: 72px;
//   left: 16px;
//   right: 16px;
//   height: auto;
//   z-index: 51;
//   overflow: hidden;
//   box-shadow: 0 2px 12px rgb(30 44 106 / 15%);
//   border-radius: 8px;
//   background-color: #ffffff;
// }

.order-modal-body {
  height: calc(100% - 48px);
}

// .order-modal-dialog.bottom-padding {
//   padding-bottom: 20px;
// }

.order-modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 50;
  background: rgba(35, 31, 32, 0.5);
  overflow: hidden;
}

.header-name {
  margin: 15px 25px 0;
}

.order-summary-section {
  margin: 25px;
  .expiry-countdown-label {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #8e919b;
  }
  .expiry-countdown-timer {
    min-width: 60px;
    display: inline-block;
    text-align: center;
    color: #3e62fe;
  }
  .header-amount {
    font-style: normal;
    font-weight: 600;
    font-size: 21px;
    line-height: 29px;
    color: #2d2e34;
    display: inline-block;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    color: #383435;
    text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.0750639);
  }

  .logo {
    vertical-align: middle;
  }
  .header-order-id {
    margin-top: 10px;
    display: inline-block;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #231f20;
  }
}

.order-detail-modal {
  overflow: scroll;
  height: 100%;
  max-height: 330px;
}

.e-money {
  // min-height: 45vh;
  bottom: 0;
  // min-height: calc(50%);
}
