.label-input{
	font-size: 14px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #524e51;
}
input[type="number"]::-webkit-outer-spin-button, 
input[type="number"]::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}
input[type="number"] {
	-moz-appearance: textfield;
}
.inputF{
	width: 100%;
	border: none;
	outline: none;
	border-bottom: solid 0.5px #9e9e9e71;
	background-color: transparent;
	
}

.titleS{
	font-size: 12px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #231f20;
}

.iframeCC{
	height: 750px;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: none;
}


.cc{
	padding: 10px 20px;
	font-size: 14px;

	strong{
		padding-bottom: 10px;
	}

}